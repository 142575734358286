.rooms-top-section {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Full device display height */
    overflow: hidden;
}

.rooms-video {
    width: 100%;
    height: 100vh;
    /* Set the video to cover the entire viewport height */
    object-fit: cover;
    /* Ensure the video covers the entire area */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    /* Keep the video behind the content */
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    /* Overlay covers the entire width */
    background: rgba(0, 0, 0, 0.5);
    /* Dark transparent background for the overlay */
    display: flex;
    justify-content: flex-start;
    /* Align content to the left */
    align-items: center;
    /* Center content vertically */
    padding-left: 50px;
    /* Adjust padding as needed */
    z-index: 1;
    color: white;
}

.rooms-heading-container {
    max-width: 50%;
    /* Adjust the width of the text container */
    text-align: left;
    margin-top: 300px;
    margin-left: 40px;
    color: white;
}

.rooms-heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
}

.rooms-caption {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #D3D3D3;
}

.rooms-contact-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #D4A017;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.rooms-contact-link:hover {
    background-color: #E1B546;
}

.rooms {
    margin-top: 20px;
    position: relative;
    z-index: 1;
    /* Higher than the video to ensure visibility */
    padding-left: 30px;
}

.rooms h1 {
    margin-bottom: 1rem;
    color: #333;
    /* Improved contrast for readability */
    font-size: 4em;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    color: #BDB76B;
}

.rooms nav {
    margin-bottom: 2rem;
}

.rooms a {
    margin-right: 30px;
    margin-left: 0%;
    margin-top: 30px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.3s ease;
    font-size: 2em;
    font-family: 'Times New Roman', Times, serif;
    color: #A9A9A9;
}

.rooms a:hover {
    text-decoration: underline;
    color: #0056b3;
}

.roo-description {
    display: flex;
    margin: 20px 0;
}

.roo-description-left {
    flex: 1;
    padding: 20px;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #A9A9A9;
    margin-left: 20px;
}

.roo-description-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.roo-image {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
    margin-left: 80px;
}

.roo-image:last-child {
    margin-bottom: 0;
}

.current-rooms-rate {
    padding: 40px 20px;
    margin-top: 50px;
    background-color: #f8f8f8;
}

.current-rooms-rate h2 {
    text-align: center;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 30px;
    font-family: 'Times New Roman', Times, serif;
}

.rates-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.rates-left,
.rates-right {
    flex: 1;
}

.rate-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 10px;
    overflow: hidden;
}

.rate-table th,
.rate-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.rate-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.rate-table td img.table-image {
    width: 100px;
    height: auto;
}

.rate-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.rate-table tr:hover {
    background-color: #f1f1f1;
}

.rate-table tr td[colspan="4"] {
    text-align: center;
    background-color: #ffd700;
    font-weight: bold;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) {
    .rooms-top-section {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rooms-heading-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .rooms-heading {
        font-size: 2rem;
        margin-bottom: 15px;
    }

    .rooms-caption {
        font-size: 1rem;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .rooms-contact-link {
        padding: 8px 16px;
        font-size: 1rem;
    }

    .roo-description {
        flex-direction: column;
        margin: 20px 10px;
    }

    .roo-description-left,
    .roo-description-right {
        padding: 15px;
        align-items: center;
    }

    .roo-description-left {
        margin-left: 0%;
    }

    .roo-image {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .current-rooms-rate {
        padding: 20px 10px;
        margin-top: 30px;
    }

    .current-rooms-rate h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .rates-container {
        flex-direction: column;
        gap: 10px;
    }

    .rate-table {
        width: 80%;
        margin: 10px 0;
    }

    .rate-table td img.table-image {
        width: 80px;
    }

    .rate-table th,
    .rate-table td {
        padding: 1px;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .rooms-top-section {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rooms-heading-container {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .rooms-heading {
        font-size: 2.5rem;
        margin-bottom: 15px;
    }

    .rooms-caption {
        font-size: 1.1rem;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    .rooms-contact-link {
        padding: 8px 16px;
        font-size: 1rem;
    }

    .roo-description {
        flex-direction: column;
        margin: 20px 15px;
    }

    .roo-description-left,
    .roo-description-right {
        padding: 15px;
        margin-left: 0;
    }

    .roo-image {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .current-rooms-rate {
        padding: 30px 15px;
        margin-top: 40px;
    }

    .current-rooms-rate h2 {
        font-size: 2.2em;
        margin-bottom: 25px;
    }

    .rates-container {
        flex-direction: column;
        gap: 15px;
    }

    .rate-table {
        width: 100%;
        margin: 15px 0;
    }

    .rate-table td img.table-image {
        width: 90px;
    }

    .rate-table th,
    .rate-table td {
        padding: 1px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .rooms-top-section {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rooms-heading-container {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .rooms-heading {
        font-size: 2.8rem;
        margin-bottom: 20px;
    }

    .rooms-caption {
        font-size: 1.2rem;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .rooms-contact-link {
        padding: 10px 18px;
        font-size: 1.1rem;
    }

    .rooms h1 {
        font-size: 2.8em;
        margin-right: 20px;
    }

    .roo-description {
        flex-direction: column;
        margin: 20px 20px;
    }

    .roo-description-left,
    .roo-description-right {
        padding: 20px;
        align-items: center;
    }

    .roo-description-left p {
        text-align: center;
    }

    .roo-image {
        width: 90%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .current-rooms-rate {
        padding: 30px 20px;
        margin-top: 40px;
    }

    .current-rooms-rate h2 {
        font-size: 2.3em;
        margin-bottom: 25px;
    }

    .rates-container {
        flex-direction: column;
        gap: 20px;
    }

    .rate-table {
        width: 80%;
        margin-left: 20px;
    }

    .rate-table td img.table-image {
        width: 100px;
    }

    .rate-table th,
    .rate-table td {
        padding: 2px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .rooms-top-section {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rooms-heading-container {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .rooms-heading {
        font-size: 3rem;
        margin-bottom: 20px;
    }

    .rooms-caption {
        font-size: 1.2rem;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    .rooms-contact-link {
        padding: 12px 20px;
        font-size: 1.2rem;
    }

    .roo-description {
        flex-direction: column;
        margin: 25px 25px;
    }

    .roo-description-left,
    .roo-description-right {
        padding: 20px;
        margin-left: 0;
    }

    .roo-image {
        width: 100%;
        margin-left: 0;
        margin-bottom: 25px;
    }

    .current-rooms-rate {
        padding: 40px 30px;
        margin-top: 50px;
    }

    .current-rooms-rate h2 {
        font-size: 2.5em;
        margin-bottom: 30px;
    }

    .rates-container {
        flex-direction: row;
        gap: 20px;
    }

    .rate-table {
        width: 100%;
        margin: 20px;
    }

    .rate-table td img.table-image {
        width: 100px;
    }

    .rate-table th,
    .rate-table td {
        padding: 12px;
    }
}