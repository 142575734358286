/* ProgressBar.css */

.progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding: 10px 0;
    position: relative;
    width: 100%;
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    flex: 1;
    z-index: 1;
}

.progress-step span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    /* Default grey color for inactive steps */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-bottom: 5px;
    z-index: 2;
}

.progress-step.completed span,
.progress-step.active span {
    background-color: #4CAF50;
    /* Green color for completed and active steps */
}

.progress-step p {
    margin-top: 25px;
    font-size: 1em;
    color: #000;
    font-weight: normal;
}

.progress-step.active p,
.progress-step.completed p {
    font-weight: bold;
}

.progress-step small {
    font-size: 0.8em;
    color: #888;
}

/* Connecting line between steps */
.progress-bar::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    height: 4px;
    background: #ddd;
    /* Default grey color for inactive steps */
    z-index: 1;
}

.progress-step.completed::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 50%);
    height: 4px;
    background: #4CAF50;
    /* Green color for completed steps */
    z-index: 1;
}

.progress-step:first-child::before {
    display: none;
    /* No line before the first step */
}

.progress-step.completed~.progress-step::before {
    background-color: #ddd;
    /* Grey line after the last completed step */
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .progress-bar {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        padding: 0;
    }

    .progress-step {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin-bottom: 15px;
    }

    .progress-step span {
        width: 30px;
        height: 30px;
        font-size: 1em;
    }

    .progress-step p {
        font-size: 0.9em;
        margin-left: 10px;
    }

    .progress-step small {
        font-size: 0.7em;
        margin-left: 10px;
    }

    .progress-bar::before {
        display: none;
    }

    .progress-step::before {
        display: none;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .progress-bar {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        padding: 0;
    }

    .progress-step {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin-bottom: 15px;
    }

    .progress-step span {
        width: 35px;
        height: 35px;
        font-size: 1em;
    }

    .progress-step p {
        font-size: 0.9em;
        margin-left: 10px;
    }

    .progress-step small {
        font-size: 0.8em;
        margin-left: 10px;
    }

    .progress-bar::before {
        display: none;
    }

    .progress-step::before {
        display: none;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .progress-bar {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-right: 20px;
    }

    .progress-step {
        flex-direction: column;
        text-align: center;
    }

    .progress-step span {
        width: 35px;
        height: 35px;
        font-size: 1.1em;
    }

    .progress-step p {
        font-size: 0.9em;
    }

    .progress-step small {
        font-size: 0.8em;
    }

    .progress-bar::before {
        width: 90%;
        left: 5%;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .progress-bar {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .progress-step {
        flex-direction: column;
        text-align: center;
    }

    .progress-step span {
        width: 40px;
        height: 40px;
        font-size: 1.2em;
    }

    .progress-step p {
        font-size: 1em;
    }

    .progress-step small {
        font-size: 0.9em;
    }

    .progress-bar::before {
        width: 90%;
        left: 5%;
    }
}

/* Desktop (min-width: 1025px) */
@media only screen and (min-width: 1025px) {
    .progress-bar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .progress-step {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .progress-step span {
        width: 40px;
        height: 40px;
        font-size: 1.2em;
    }

    .progress-step p {
        font-size: 1em;
    }

    .progress-step small {
        font-size: 0.8em;
    }

    .progress-bar::before {
        width: 80%;
        left: 10%;
    }
}