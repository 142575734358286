.custom-booking-page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 40px;
    font-family: Arial, sans-serif;
    color: #333;
    gap: 30px;
    /* Add space between the two boxes */
}

.custom-booking-summary {
    background-color: #fff;
    border: 2px solid #D4A017;
    border-radius: 15px;
    padding: 20px;
    width: 350px;
    /* Width of the left box */
    height: auto;
    /* Auto height to accommodate content */
    max-height: 500px;
    /* Set max-height for scrolling */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 20px;
    /* Keep the summary fixed while scrolling the form */
    overflow-y: auto;
    /* Allow scrolling if content overflows */
}

.custom-room-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin-bottom: 10px;
    object-fit: cover;
}

.custom-booking-summary-details h2 {
    font-size: 1.6rem;
    color: #D4A017;
    margin-bottom: 8px;
    font-weight: bold;
}

.custom-booking-summary-details p {
    margin: 6px 0;
    font-size: 1rem;
    line-height: 1.4;
}

.custom-services-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 5px;
    /* Add space between service items */
}

.custom-service-item {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #666;
}

.custom-service-item svg {
    margin-right: 5px;
    color: #D4A017;
}

.custom-form-container {
    width: 100%;
    max-width: 800px;
}

.custom-booking-form {
    background-color: #fff;
    border: 2px solid #D4A017;
    border-radius: 15px;
    padding: 30px;
    width: 100%;
    /* Width of the right box */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
    max-height: 85vh;
    /* Make the right box taller */
    overflow-y: auto;
    /* Allow scrolling if form content overflows */
}

.custom-booking-form h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

.custom-booking-form .custom-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 1.1rem;
    color: #333;
}

.custom-booking-form .custom-input,
.custom-booking-form .custom-textarea,
.custom-booking-form .custom-select {
    padding: 12px;
    font-size: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 10px;
    box-sizing: border-box;
    width: 100%;
}

.custom-booking-form .custom-textarea {
    resize: vertical;
    height: 100px;
    /* Increase height of textarea */
}

.custom-booking-form .custom-terms-label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
}

.custom-booking-form .custom-terms-label input[type="checkbox"] {
    margin-right: 10px;
}

.custom-booking-form .custom-terms-label a {
    color: #D4A017;
    text-decoration: none;
    font-weight: bold;
}

.custom-booking-form .custom-terms-label a:hover {
    text-decoration: underline;
}

.custom-booking-form .custom-checkout-button {
    width: 100%;
    padding: 12px;
    background-color: #D4A017;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 25px;
    transition: background-color 0.3s;
    font-size: 1.1rem;
}

.custom-booking-form .custom-checkout-button:hover {
    background-color: #E1B546;
}

.custom-go-back-container {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
}

.custom-go-back-button {
    padding: 8px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    font-size: 1rem;
    width: 100%;
    margin-top: 20px;
    margin-left: 30px;
}

.custom-go-back-button:hover {
    background-color: #b3b3b3;
}

.custom-booking-form a {
    color: #D4A017;
    text-decoration: none;
}

.custom-booking-form a:hover {
    text-decoration: underline;
}

.custom-form-container {
    margin-top: 20px;
    /* Ensure there is some space between the form and the "Go Back" button */
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .custom-booking-page-container {
        flex-direction: column;
        margin: 20px;
        gap: 20px;
    }

    .custom-booking-summary {
        width: 80%;
        max-height: 300px;
        position: relative;
        top: 0;
    }

    .custom-form-container {
        width: 80%;
    }

    .custom-booking-form {
        padding: 20px;
        max-height: none;
    }

    .custom-go-back-button {
        margin-left: 0;
        width: 100%;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .custom-booking-page-container {
        flex-direction: column;
        margin: 30px;
        gap: 25px;
    }

    .custom-booking-summary {
        width: 100%;
        max-height: 350px;
        position: relative;
        top: 0;
    }

    .custom-form-container {
        width: 100%;
    }

    .custom-booking-form {
        padding: 25px;
        max-height: none;
    }

    .custom-go-back-button {
        margin-left: 0;
        width: 100%;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .custom-booking-page-container {
        flex-direction: column;
        margin: 30px;
        gap: 30px;
    }

    .custom-booking-summary {
        width: 90%;
        max-height: 400px;
        position: relative;
        top: 0;
    }

    .custom-form-container {
        width: 88%;
        margin-right: 20px;
    }

    .custom-booking-form {
        padding: 25px;
        max-height: none;
        padding-right: 20px;
    }

    .custom-go-back-button {
        margin-left: 30px;
        width: 100%;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .custom-booking-page-container {
        flex-direction: row;
        margin: 40px;
        gap: 30px;
    }

    .custom-booking-summary {
        width: 100%;
        max-height: 450px;
        position: sticky;
        top: 20px;
    }

    .custom-form-container {
        width: 100%;
    }

    .custom-booking-form {
        padding: 30px;
        max-height: none;
    }

    .custom-go-back-button {
        margin-left: 0;
        width: 100%;
    }
}

/* Desktop (min-width: 1025px) */
@media only screen and (min-width: 1025px) {
    .custom-booking-page-container {
        flex-direction: row;
        margin: 40px;
        gap: 30px;
    }

    .custom-booking-summary {
        width: 350px;
        max-height: 500px;
        position: sticky;
        top: 20px;
    }

    .custom-form-container {
        max-width: 800px;
    }

    .custom-booking-form {
        padding: 30px;
        max-height: 85vh;
    }

    .custom-go-back-button {
        margin-left: 30px;
        width: 100%;
    }
}