/* src/styles/Services.css */

.services {
    padding: 2rem;
    background-color: #f4f4f4;
}

.services h1 {
    margin-top: 0;
}

.services {
    padding: 40px;
    margin: 0 auto;
    max-width: 1024px;
}

.services h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.services p {
    font-size: 1.1rem;
    color: #666;
    text-align: center;
    margin-bottom: 20px;
}

.services-box {
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    background-color: #f9f9f9;
    width: 95%;
    /* Increase the width to 95% */
    min-height: 400px;
    /* Add a minimum height */
    height: auto;
    /* Ensure height adapts to content */
}

.services-column {
    width: 45%;
    /* Adjust column width to fit the larger box */
}

.services-column p {
    font-size: 1rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    color: #333;
}

.services-column p svg {
    margin-right: 10px;
    color: #666;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) {
    .services {
        padding: 20px;
        margin: 0 10px;
        /* Maintain side margins for overall section */
    }

    .services h1 {
        font-size: 2rem;
        margin-bottom: 15px;
    }

    .services p {
        font-size: 1rem;
        margin-bottom: 15px;
    }

    .services-box {
        flex-direction: column;
        width: 90%;
        /* Slightly reduced width to align more to the left */
        padding: 15px;
        margin-top: 20px;
        margin-left: 0;
        /* Align to the left */
        margin-right: 60px;
        /* Center with left bias */
        min-height: auto;
        /* Remove minimum height for flexibility */
        margin-bottom: 20px;
        /* Added bottom margin for spacing */
    }

    .services-column {
        width: 100%;
        /* Full width for columns */
        margin-bottom: 20px;
        /* Add spacing between columns */
        padding: 10px;
        /* Added padding for inner spacing */
    }

    .services-column p {
        font-size: 0.9rem;
        /* Slightly smaller font size */
        margin-bottom: 10px;
        /* Added margin for spacing */
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .services {
        padding: 30px;
        margin: 0 15px;
        /* Maintain side margins for overall section */
    }

    .services h1 {
        font-size: 2.2rem;
        margin-bottom: 18px;
    }

    .services p {
        font-size: 1rem;
        margin-bottom: 18px;
    }

    .services-box {
        flex-direction: column;
        width: 85%;
        /* Slightly reduced width to align more to the left */
        padding: 20px;
        margin-top: 25px;
        margin-left: 0;
        /* Align to the left */
        margin-right: auto;
        /* Center with left bias */
        min-height: auto;
        /* Remove minimum height */
        margin-bottom: 25px;
        /* Added bottom margin for spacing */
    }

    .services-column {
        width: 100%;
        /* Full width */
        margin-bottom: 20px;
        /* Spacing between columns */
        padding: 12px;
        /* Added padding for inner spacing */
    }

    .services-column p {
        font-size: 1rem;
        margin-bottom: 12px;
        /* Adjusted margin */
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .services {
        padding: 35px;
        margin: 0 20px;
        /* Maintain side margins for overall section */
    }

    .services h1 {
        font-size: 2.3rem;
        margin-bottom: 20px;
    }

    .services p {
        font-size: 1.05rem;
        margin-bottom: 20px;
    }

    .services-box {
        flex-direction: column;
        width: 80%;
        /* Slightly reduced width to align more to the left */
        padding: 25px;
        margin-top: 25px;
        margin-left: 0;
        /* Align to the left */
        margin-right: auto;
        /* Center with left bias */
        min-height: auto;
        /* Remove minimum height */
        margin-bottom: 30px;
        /* Added bottom margin for spacing */
    }

    .services-column {
        width: 100%;
        /* Full width */
        margin-bottom: 25px;
        /* Spacing between columns */
        padding: 15px;
        /* Added padding for inner spacing */
    }

    .services-column p {
        font-size: 1rem;
        margin-bottom: 15px;
        /* Adjusted margin */
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .services {
        padding: 40px;
        margin: 0 25px;
        /* Maintain side margins for overall section */
    }

    .services h1 {
        font-size: 2.4rem;
        margin-bottom: 25px;
    }

    .services p {
        font-size: 1.1rem;
        margin-bottom: 25px;
    }

    .services-box {
        flex-direction: column;
        width: 75%;
        /* Slightly reduced width to align more to the left */
        padding: 30px;
        margin-top: 30px;
        margin-left: 0;
        /* Align to the left */
        margin-right: auto;
        /* Center with left bias */
        min-height: auto;
        /* Remove minimum height */
        margin-bottom: 35px;
        /* Added bottom margin for spacing */
    }

    .services-column {
        width: 100%;
        /* Full width */
        margin-bottom: 30px;
        /* Spacing between columns */
        padding: 20px;
        /* Added padding for inner spacing */
    }

    .services-column p {
        font-size: 1rem;
        margin-bottom: 20px;
        /* Adjusted margin */
    }
}