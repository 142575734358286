/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
}

/* Container for the confirmation details */
.confirm-container {
    max-width: 900px;
    margin: 50px auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Confirmation message style */
.confirm-message {
    background-color: #e6f9e7;
    color: #2e7d32;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 1.2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Booking Details */
.confirm-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.confirm-detail-item {
    width: 48%;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #333;
}

.confirm-detail-item span.confirm-detail-label {
    font-weight: bold;
    color: #666;
}

@media screen and (max-width: 600px) {
    .confirm-detail-item {
        width: 100%;
    }
}

/* Title Style */
.confirm-details h2 {
    font-size: 2em;
    margin-bottom: 25px;
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #D4A017;
    padding-bottom: 10px;
}

/* Action buttons */
.confirm-actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.confirm-actions button {
    padding: 12px 20px;
    margin: 0 10px;
    background-color: #D4A017;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    font-size: 1.1rem;
}

.confirm-actions button:hover {
    background-color: #E1B546;
}

/* Footer Style */
.confirm-footer {
    text-align: center;
    margin-top: 40px;
    font-size: 0.9em;
    color: #888;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .confirm-container {
        padding: 20px;
        margin: 20px;
        box-shadow: none;
    }

    .confirm-message {
        font-size: 1em;
        padding: 10px;
    }

    .confirm-details h2 {
        font-size: 1.5em;
        margin-bottom: 20px;
        padding-bottom: 8px;
    }

    .confirm-detail-item {
        width: 90%;
        margin-bottom: 10px;
        font-size: 0.9rem;
    }

    .confirm-actions {
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }

    .confirm-actions button {
        width: 100%;
        font-size: 1rem;
        padding: 10px;
    }

    .confirm-footer {
        font-size: 0.8em;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .confirm-container {
        padding: 25px;
        margin: 30px auto;
        box-shadow: none;
    }

    .confirm-message {
        font-size: 1.1em;
        padding: 12px;
    }

    .confirm-details h2 {
        font-size: 1.75em;
        margin-bottom: 22px;
        padding-bottom: 8px;
    }

    .confirm-detail-item {
        width: 100%;
        margin-bottom: 10px;
        font-size: 0.95rem;
    }

    .confirm-actions {
        flex-direction: row;
        justify-content: space-around;
    }

    .confirm-actions button {
        width: 45%;
        font-size: 1rem;
        padding: 10px;
    }

    .confirm-footer {
        font-size: 0.85em;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .confirm-container {
        padding: 30px;
        margin: 40px auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .confirm-message {
        font-size: 1.2em;
        padding: 15px;
    }

    .confirm-details h2 {
        font-size: 1.9em;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }

    .confirm-detail-item {
        width: 90%;
        font-size: 1rem;
    }

    .confirm-actions {
        flex-direction: row;
        justify-content: space-around;
    }

    .confirm-actions button {
        width: 45%;
        font-size: 1.05rem;
        padding: 12px;
    }

    .confirm-footer {
        font-size: 0.85em;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .confirm-container {
        padding: 30px;
        margin: 50px auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .confirm-message {
        font-size: 1.2em;
        padding: 15px;
    }

    .confirm-details h2 {
        font-size: 2em;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }

    .confirm-detail-item {
        width: 48%;
        font-size: 1rem;
    }

    .confirm-actions {
        flex-direction: row;
        justify-content: center;
    }

    .confirm-actions button {
        width: auto;
        font-size: 1.1rem;
        padding: 12px 20px;
    }

    .confirm-footer {
        font-size: 0.9em;
    }
}