.room-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-top: 40px;
}

.room-cards-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
    gap: 20px;
    justify-content: flex-start;
    min-width: 100%;
    /* Ensure that the container is wide enough to fit all cards */

    padding-left: 50px;
    padding-right: 20px;

}

.room-card {
    flex: 0 0 20%;
    /* Show 4 cards at a time */
    max-width: 300px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s;
    margin-left: 40px;
}

.room-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.room-section-title {
    text-align: center;
    font-size: 4em;
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    color: #A9A9A9;
}

.room-section-second {
    text-align: center;
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;

    color: #A9A9A9;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    width: 50px;
    /* Ensure button size is sufficient */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.carousel-button.left {
    left: 0;
}

.carousel-button.right {
    right: 0;
}

.image-description-title {
    text-align: center;
    font-size: 4em;
    margin-top: 60px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    color: #A9A9A9;
}

.image-description-second {
    text-align: center;
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;

    color: #A9A9A9;
}

.image-description-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px #BDB76B;
    margin-top: 50px;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.image-column,
.description-column {
    width: 50%;
    /* Equal width for both columns */
    padding: 20px;
    /* Padding inside each column */
    box-sizing: border-box;
}

.image-column {
    position: relative;
    padding-right: 10px;
    /* Extra padding to avoid touching the vertical line */
}

.description-column {
    padding-left: 10px;
    /* Extra padding to avoid touching the vertical line */
}

.image-slide {
    width: 100%;
    transition: opacity 1s ease-in-out;
    border-radius: 10px;
    /* Optional rounded corners */
}

.description {
    font-size: 1.2em;
    padding: 20px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.highlighted {
    background-color: #BDB76B;
    ;
    font-weight: bold;
}

.image-description-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: #ccc;
    transform: translateX(-50%);
}

/* Gallery Section Styles */
.gallery-section-title {
    text-align: center;
    margin-top: 50px;
    font-size: 4em;
    color: #A9A9A9;
    font-family: 'Times New Roman', Times, serif;
}

.gallery-section-second {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #A9A9A9;
}

.gallery-cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

.gallery-card {
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease;
    text-decoration: none;
    color: inherit;
}

.gallery-card img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.gallery-card h2 {
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.gallery-card:hover {
    transform: scale(1.05);
    background-color: #e0e0e0;
}

.feedback-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 50px 0;
    position: relative;
    width: 100%;
    padding: 20px;
}

.feedback-cards {
    display: flex;
    gap: 20px;
    width: 100%;
    position: relative;
    white-space: nowrap;
    /* Ensures the cards are on a single line */
}

.feedback-cards.top {
    animation: scroll-left 60s linear infinite;
    /* Slower animation */
}

.feedback-cards.bottom {
    animation: scroll-right 60s linear infinite;
    /* Slower animation */
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll-right {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.feedback-card {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 250px;
    max-width: 350px;
    /* Increased width for feedback cards */
    height: 300px;
    /* Further increased height for feedback cards */
    text-align: center;
    transition: transform 0.3s;
    flex-shrink: 0;
    /* Ensures the card won't shrink */
    box-sizing: border-box;
    /* Ensures padding is included in width */
    overflow: hidden;
    /* Prevents content overflow */
    margin-top: 20px;
}

.feedback-card h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
    white-space: nowrap;
    /* Prevents title from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Adds ellipsis for long titles */
}

.feedback-card p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
    white-space: normal;
    /* Allows text wrapping */
    overflow: hidden;
    /* Prevents overflow */
    text-overflow: ellipsis;
    /* Adds ellipsis for long text */
    word-wrap: break-word;
    /* Ensures long words break into the next line */
    line-height: 1.4;
    /* Improves readability */
    max-height: 150px;
    /* Further increased max height to fit more text */
    padding-bottom: 5px;
    /* Adds some space at the bottom */
    margin-bottom: 10px;
    /* Ensures there is enough space at the bottom */
}

/* Stop animation on hover */
.feedback-cards:hover {
    animation-play-state: paused;
}

.feedback-section-title {
    text-align: center;
    font-size: 3.5em;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    color: #A9A9A9;
    margin-top: 40px;
    padding-top: 20px;
}

.feedback-section-subtitle {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #777;
    font-family: 'Arial', sans-serif;
}

.company-section-title {
    text-align: center;
    margin: 50px 0 20px;
    font-size: 3.5rem;
    color: #777;
    font-family: 'Times New Roman', Times, serif;
}

.company-section-link {
    text-align: center;
    font-size: 2rem;
    color: black;
}

.company-section-link a {
    color: black;
}

.company-video-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.company-video {
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .room-cards-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0;
        padding-left: 10px;
        padding-right: 10px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .room-card {
        flex: 0 0 70%;
        width: 60%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .room-card img {
        width: 70%;
        height: 150px;
        object-fit: cover;
    }

    .room-section-title {
        text-align: center;
        font-size: 3em;
        margin-top: 60px;
        margin-bottom: 20px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
        color: #A9A9A9;
        margin-right: 20px;
    }

    .room-section-second {
        text-align: center;
        font-size: 1em;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;

        color: #A9A9A9;
    }

    .carousel-button {
        display: flex;
    }

    .image-description-container {
        flex-direction: column;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        align-items: center;
        text-align: center;
    }

    .image-column,
    .description-column {
        width: 100%;
        padding: 10px;
    }

    .image-description-container::before {
        display: none;
        /* Remove the dividing line on mobile */
    }

    .gallery-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .gallery-card {
        background-color: #f5f5f5;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        transition: transform 0.3s ease;
        text-decoration: none;
        color: inherit;
    }

    .gallery-card img {
        width: 300px;
        height: 150px;
        object-fit: cover;
        border-bottom: 1px solid #ddd;
    }

    .gallery-card h2 {
        padding: 10px;
        font-size: 2rem;
        font-weight: bold;
        color: #333;
    }

    .gallery-card:hover {
        transform: scale(1.05);
        background-color: #e0e0e0;
    }

    .feedback-cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .feedback-card {
        min-width: 100%;
        max-width: 100%;
    }

    @keyframes scroll-left {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes scroll-right {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .room-cards-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0;
        padding-left: 10px;
        padding-right: 10px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .room-card {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .carousel-button {
        display: flex;
    }

    .image-description-container {
        flex-direction: column;
        padding: 15px;
        margin-left: 10px;
        margin-right: 10px;
        align-items: center;
        text-align: center;
    }

    .image-column,
    .description-column {
        width: 100%;
        padding: 10px;
    }

    .image-description-container::before {
        display: none;
        /* Remove the dividing line on mobile */
    }

    .gallery-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .feedback-cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .feedback-card {
        min-width: 100%;
        max-width: 100%;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .room-cards-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0;
        padding-left: 10px;
        padding-right: 10px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .room-card {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .room-card img {
        width: 70%;
        height: 150px;
        object-fit: cover;
    }

    .room-section-title {
        text-align: center;
        font-size: 3em;
        margin-top: 60px;
        margin-bottom: 20px;
        font-weight: bold;
        font-family: 'Times New Roman', Times, serif;
        color: #A9A9A9;
    }

    .room-section-second {
        text-align: center;
        font-size: 1em;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;

        color: #A9A9A9;
    }

    .carousel-button {
        display: flex;
    }

    .image-description-container {
        flex-direction: column;
        padding: 20px;
        margin-left: 10px;
        margin-right: 10px;
        align-items: center;
        text-align: center;
    }

    .image-column,
    .description-column {
        width: 100%;
        padding: 15px;
    }

    .image-description-container::before {
        display: none;
        /* Remove the dividing line on mobile */
    }

    .gallery-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .feedback-cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .feedback-card {
        min-width: 100%;
        max-width: 100%;
    }

    @keyframes scroll-left {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes scroll-right {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .room-cards-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0;
        padding-left: 10px;
        padding-right: 10px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .room-card {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .carousel-button {
        display: flex;
    }

    .image-description-container {
        flex-direction: column;
        padding: 20px;
        margin-left: 10px;
        margin-right: 10px;
        align-items: center;
        text-align: center;
    }

    .image-column,
    .description-column {
        width: 100%;
        padding: 20px;
    }

    .image-description-container::before {
        display: none;
        /* Remove the dividing line on mobile */
    }

    .gallery-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .feedback-cards {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .feedback-card {
        min-width: 100%;
        max-width: 100%;
    }
}