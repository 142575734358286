.checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    max-width: 600px;
    font-family: Arial, sans-serif;
}

.checkout-details {
    width: 100%;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 25px;
    box-sizing: border-box;

}

.checkout-details h2 {
    font-size: 2em;
    margin-bottom: 25px;
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #D4A017;
    padding-bottom: 10px;
    text-align: center;

}

.details-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #333;
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
}

.details-label {
    font-weight: bold;
    color: #666;
    flex: 1;
    text-align: left;
    margin-right: 10px;
}

.details-value {
    color: #333;
    flex: 1;
    text-align: right;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.payment-options {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
}

.payment-options h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
    text-align: center;
}

.payment-methods {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payment-method {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-method:hover {
    background-color: #f7f7f7;
}

.payment-method input[type="radio"] {
    margin-right: 10px;
    margin-bottom: 0;
}

.payment-method label {
    font-size: 1rem;
    color: #666;
    font-weight: bold;
}

.payment-methods .payment-method:not(:last-child) {
    margin-right: 10px;
}

.checkout-button {
    width: 100%;
    padding: 12px;
    background-color: #D4A017;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    font-size: 1.1rem;
    margin-top: 20px;
}

.checkout-button:hover {
    background-color: #E1B546;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .checkout-container {
        margin: 20px auto;
        padding: 0 10px;
        max-width: 100%;
    }

    .checkout-details {
        padding: 20px;
        margin-bottom: 15px;
    }

    .checkout-details h2 {
        font-size: 1.5em;
        margin-bottom: 20px;
        padding-bottom: 8px;
    }

    .details-item {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        font-size: 0.9rem;
    }

    .details-label {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .details-value {
        text-align: left;
    }

    .payment-options {
        padding: 15px;
    }

    .payment-options h2 {
        font-size: 1.5em;
        margin-bottom: 15px;
    }

    .payment-methods {
        flex-direction: column;
        gap: 10px;
    }

    .payment-method {
        padding: 10px;
    }

    .checkout-button {
        font-size: 1rem;
        padding: 10px;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .checkout-container {
        margin: 30px auto;
        padding: 0 20px;
        max-width: 100%;
    }

    .checkout-details {
        padding: 25px;
        margin-bottom: 20px;
    }

    .checkout-details h2 {
        font-size: 1.8em;
        margin-bottom: 22px;
        padding-bottom: 8px;
    }

    .details-item {
        flex-direction: row;
        font-size: 1rem;
    }

    .details-label {
        margin-right: 10px;
    }

    .details-value {
        text-align: right;
    }

    .payment-options {
        padding: 20px;
    }

    .payment-options h2 {
        font-size: 1.6em;
        margin-bottom: 18px;
    }

    .payment-methods {
        flex-direction: column;
        gap: 10px;
    }

    .payment-method {
        padding: 10px;
    }

    .checkout-button {
        font-size: 1.1rem;
        padding: 12px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .checkout-container {
        margin: 40px auto;
        padding: 0 30px;
        max-width: 100%;
    }

    .checkout-details {
        padding: 30px;
        margin-bottom: 25px;
    }

    .checkout-details h2 {
        font-size: 2em;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }

    .details-item {
        flex-direction: row;
        justify-content: space-between;
        font-size: 1rem;
    }

    .payment-options {
        padding: 25px;
    }

    .payment-options h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .payment-methods {
        flex-direction: row;
        gap: 10px;
    }

    .payment-method {
        flex: 1;
        padding: 15px;
    }

    .checkout-button {
        font-size: 1.1rem;
        padding: 12px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .checkout-container {
        margin: 50px auto;
        padding: 0 40px;
        max-width: 100%;
    }

    .checkout-details {
        padding: 30px;
        margin-bottom: 25px;
    }

    .checkout-details h2 {
        font-size: 2em;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }

    .details-item {
        flex-direction: row;
        justify-content: space-between;
        font-size: 1rem;
    }

    .payment-options {
        padding: 30px;
    }

    .payment-options h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .payment-methods {
        flex-direction: row;
        gap: 10px;
    }

    .payment-method {
        flex: 1;
        padding: 15px;
    }

    .checkout-button {
        font-size: 1.1rem;
        padding: 12px;
    }
}