.contact-top-section {
    position: relative;
}

.contact-image-container {
    width: 100%;
    height: 100vh;
    /* Set the height to 100% of the viewport height */
    overflow: hidden;
    position: relative;
}

.contact-image {
    width: 100%;
    height: 100%;
    /* Ensure the image fills the container */
    object-fit: cover;
    /* Ensure the image covers the entire container without distorting */
}

.contact-heading {
    position: absolute;
    bottom: 100px;
    /* Position the heading 20px from the bottom of the container */
    left: 200px;
    /* Position the heading 20px from the left of the container */
    margin: 0;
    padding: 10px 20px;
    color: white;
    font-size: 4.5em;
    /* Adjust font size as needed */
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;

}

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-top: 30px;
}

.contact-form {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input-group input {
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}

.contact-form textarea {
    height: 120px;
    resize: none;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.checkbox-container label {
    margin: 0;
    color: #6c757d;
    /* Adjust color to match the provided design */
    font-size: 16px;
}

.contact-submit-button {
    width: 100%;
    background-color: #d4af37;
    color: white;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-submit-button:hover {
    background-color: #b8932f;
}

/* New styles for the map section */
.map-section {
    padding: 50px 0;
    background-color: #f0f0f0;
    /* Optional background color */
    margin-top: 30px;
    margin-right: 30px;
    margin-left: 30px;
}

.map-heading {
    margin-bottom: 20px;
    font-size: 3em;
    color: #333;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.map-section iframe {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    margin-right: 30px;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .contact-image-container {
        height: 70vh;
        /* Set the height to 70% of the viewport height */
    }

    .contact-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-heading {
        font-size: 2em;
        bottom: 20px;
        left: 10px;
        text-align: center;
    }

    .contact-form-container {
        flex-direction: column;
        padding: 10px;
        margin-top: 20px;
    }

    .contact-form {
        width: 100%;
    }

    .map-section {
        padding: 20px 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .map-heading {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .map-section iframe {
        width: 100%;
        height: 200px;
        margin-right: 0;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .contact-image-container {
        height: 70vh;
    }

    .contact-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-heading {
        font-size: 2.5em;
        bottom: 30px;
        left: 20px;
        text-align: center;
    }

    .contact-form-container {
        flex-direction: column;
        padding: 15px;
        margin-top: 30px;
    }

    .contact-form {
        width: 100%;
    }

    .map-section {
        padding: 30px 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .map-heading {
        font-size: 2.5em;
        margin-bottom: 15px;
    }

    .map-section iframe {
        width: 100%;
        height: 250px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .contact-image-container {
        height: 70vh;
    }

    .contact-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-heading {
        font-size: 3em;
        bottom: 40px;
        left: 50px;
        text-align: center;
        margin-right: 30px;
    }

    .contact-form-container {
        flex-direction: column;
        padding: 20px;
        margin-top: 40px;
    }

    .contact-form {
        width: 80%;
    }

    .map-section {
        padding: 40px 30px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .map-heading {
        font-size: 3em;
        margin-bottom: 20px;
    }

    .map-section iframe {
        width: 100%;
        height: 300px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .contact-image-container {
        height: 70vh;
    }

    .contact-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-heading {
        font-size: 3.5em;
        bottom: 50px;
        left: 100px;
        text-align: center;
    }

    .contact-form-container {
        flex-direction: column;
        padding: 25px;
        margin-top: 50px;
    }

    .contact-form {
        width: 100%;
    }

    .map-section {
        padding: 50px 40px;
        margin-left: 40px;
        margin-right: 40px;
    }

    .map-heading {
        font-size: 3.5em;
        margin-bottom: 25px;
    }

    .map-section iframe {
        width: 100%;
        height: 350px;
    }
}

/* Desktop (min-width: 1025px) */
@media only screen and (min-width: 1025px) {
    .contact-image-container {
        height: 70vh;
    }

    .contact-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .contact-heading {
        font-size: 4.5em;
        bottom: 100px;
        left: 200px;
    }

    .contact-form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        margin-top: 30px;
    }

    .contact-form {
        width: 100%;
        max-width: 600px;
    }

    .map-section {
        padding: 50px 0;
        margin-left: 30px;
        margin-right: 30px;
    }

    .map-heading {
        font-size: 3em;
        text-align: center;
    }

    .map-section iframe {
        width: 100%;
        height: 400px;
    }
}