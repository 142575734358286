.admin-dashboard {
    display: flex;
}

.sidebar {
    width: 250px;
    background-color: #333;
    color: white;
    height: 100vh;
    padding: 20px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.hotel-logo {
    width: 100px;
    margin-bottom: 10px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 20px;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.admin-header h1 {
    margin: 0;
    font-size: 2em;
}

.admin-header-right {
    display: flex;
    align-items: center;
}

.admin-open-site {
    margin-right: 20px;
    color: #007bff;
    text-decoration: none;
}

.admin-user {
    font-weight: bold;
}

.admin-overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.overview-item {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    flex-grow: 1;
    margin-right: 20px;
}

.overview-item:last-child {
    margin-right: 0;
}

.overview-item h2 {
    margin: 0;
    font-size: 3em;
    color: #333;
}

.overview-item p {
    margin: 0;
    font-size: 1.2em;
    color: #666;
}

.latest-bookings {
    margin-bottom: 40px;
}

.latest-bookings h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.latest-bookings table {
    width: 100%;
    border-collapse: collapse;
}

.latest-bookings table th,
.latest-bookings table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.latest-bookings table th {
    background-color: #f0f0f0;
}

.latest-bookings table td {
    background-color: #fff;
}

.logout-button {
    color: white;
    font-size: 1.2em;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.logout-button:hover {
    text-decoration: underline;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .admin-dashboard {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        padding: 10px;
        margin-bottom: 20px;
    }

    .hotel-logo {
        width: 80px;
    }

    .sidebar ul li a {
        font-size: 1rem;
        text-align: center;
    }

    .main-content {
        padding-left: 0%;
    }

    .admin-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .admin-header h1 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .admin-header-right {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-open-site {
        margin-bottom: 10px;
    }

    .admin-overview {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .overview-item {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .latest-bookings h2 {
        font-size: 1.5em;
        margin-bottom: 15px;
    }

    .latest-bookings table th,
    .latest-bookings table td {
        padding: 10px;
        width: fit-content;
    }

    .logout-button {
        font-size: 1em;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .admin-dashboard {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        padding: 15px;
        margin-bottom: 20px;
    }

    .hotel-logo {
        width: 90px;
    }

    .sidebar ul li a {
        font-size: 1.1rem;

    }

    .main-content {
        padding: 15px;
    }

    .admin-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 25px;
    }

    .admin-header h1 {
        font-size: 1.8em;
        margin-bottom: 15px;
    }

    .admin-header-right {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-overview {
        flex-direction: column;
        margin-bottom: 30px;
    }

    .overview-item {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .latest-bookings h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .latest-bookings table th,
    .latest-bookings table td {
        padding: 12px;
    }

    .logout-button {
        font-size: 1.1em;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .admin-dashboard {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        padding: 20px;
        margin-bottom: 25px;
    }

    .hotel-logo {
        width: 100px;
    }

    .sidebar ul li a {
        font-size: 1.2rem;
        text-align: center;
    }

    .main-content {
        padding-left: 0%;
        width: 80%;
        padding-right: 0%;
    }

    .admin-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    .admin-header h1 {
        font-size: 2em;
        margin-bottom: 20px;
    }

    .admin-header-right {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-overview {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .overview-item {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .latest-bookings h2 {
        font-size: 1.8em;
        margin-bottom: 20px;
    }

    .latest-bookings h2 {
        margin-left: 30px;
    }

    .latest-bookings table th,
    .latest-bookings table td {
        padding: 12px;
    }

    .logout-button {
        font-size: 1.1em;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .admin-dashboard {
        flex-direction: row;
    }

    .sidebar {
        width: 250px;
        height: 100vh;
        padding: 20px;
    }

    .hotel-logo {
        width: 100px;
    }

    .sidebar ul li a {
        font-size: 1.2rem;
    }

    .main-content {
        padding: 20px;
    }

    .admin-header {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .admin-header h1 {
        font-size: 2em;
    }

    .admin-header-right {
        flex-direction: row;
    }

    .admin-overview {
        flex-direction: row;
        margin-bottom: 40px;
    }

    .overview-item {
        margin-right: 20px;
    }

    .overview-item:last-child {
        margin-right: 0;
    }

    .latest-bookings h2 {
        font-size: 2em;
    }

    .latest-bookings table th,
    .latest-bookings table td {
        padding: 15px;
    }

    .logout-button {
        font-size: 1.2em;
    }
}