.footer {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    font-family: Arial, sans-serif;
    height: 100vh;
    /* Set the height to cover the full viewport */
    padding: 0;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 40px;
}

.footer-overlay {
    position: absolute;
    /* Position overlay absolutely within the footer */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Ensure the overlay covers the full height */
    background-color: rgba(0, 0, 0, 0.7);
    /* Black overlay with opacity */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 20px;
    /* Padding inside the overlay */
}

.footer-top {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.footer-links a {
    color: #e7c061;
    margin: 40px 15px;
    text-decoration: none;
    font-size: 1.5em;
}


.footer-links a:hover {
    color: #fff;
}

.footer-middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.footer-info {
    max-width: 33%;
    margin-bottom: 100px;
}

.hotel-name {
    font-size: 3.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.hotel-address {
    font-size: 16px;
    margin-bottom: 10px;
}

.footer-contact {
    margin-top: 150px;
    margin-left: 20px;
}

.footer-contact p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footer-contact p svg {
    margin-right: 10px;
}

.footer-social {
    max-width: 33%;
    text-align: right;
    margin-top: 150px;
}

.footer-social p {
    margin-bottom: 10px;
    margin-right: 220px;
}

.subscribe-section {
    display: flex;
    align-items: center;
}

.footer-social input {
    padding: 10px;
    width: 200px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
}

.subscribe-button {
    padding: 10px 20px;
    border: none;
    background-color: #e7c061;
    color: #2c2c2c;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.social-icons {
    margin-top: 20px;
    margin-right: 90px;
}

.social-icons a {
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
    transition: color 0.3s;
    margin-top: 20px;
}

.social-icons a:hover {
    color: #e7c061;
}

.footer-bottom {
    text-align: center;
}

.footer-bottom p {
    margin-bottom: 10px;
}

.footer-bottom a {
    color: white;
}

.footer-bottom-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;
}

.footer-bottom-links a:hover {
    color: #e7c061;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .footer-overlay {
        padding: 20px 10px;
    }

    .footer-top {
        display: none;
        /* Hide the footer-top section */
    }

    .footer-middle {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

    }

    .footer-links {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 80px;
        font-size: 1em;
    }

    .footer-links a {
        margin-left: 40px;
        font-size: 0.6em;
        margin-right: 40px;
    }

    .footer-info,
    .footer-social {
        max-width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .hotel-name {
        font-size: 3em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .footer-social p {
        margin-right: 0;
    }

    .footer-contact {
        margin-top: 50px;
        margin-left: 0;
        text-align: center;
    }

    .footer-social input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .social-icons {
        margin-top: 10px;
        margin-right: 0;
        text-align: center;
    }

    .footer-bottom {
        color: #e7c061;
    }

    .footer-bottom a {
        color: #e7c061;
    }

    .footer-bottom p {
        margin-bottom: 10px;
        color: #e7c061;

    }

    .footer-bottom-links a {
        margin: 0 5px;
        color: #e7c061;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .footer-overlay {
        padding: 30px 15px;
    }

    .footer-top,
    .footer-middle {
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-links a {
        margin: 12px 0;
        font-size: 1.3em;
    }

    .footer-info,
    .footer-social {
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .footer-social p {
        margin-right: 0;
    }

    .footer-contact {
        margin-top: 80px;
        margin-left: 0;
        text-align: center;
    }

    .footer-social input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .social-icons {
        margin-top: 15px;
        margin-right: 0;
        text-align: center;
    }

    .footer-bottom p {
        margin-bottom: 10px;
    }

    .footer-bottom-links a {
        margin: 0 7px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .footer-overlay {
        padding: 35px 20px;
    }

    .footer-top {
        display: none;
        /* Hide the footer-top section */
    }


    .footer-middle {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-links a {
        margin: 15px 0;
        font-size: 1.4em;
    }

    .footer-info,
    .footer-social {
        max-width: 100%;
        text-align: center;
        margin-bottom: 40px;
    }

    .footer-social p {
        margin-right: 0;
    }

    .footer-contact {
        margin-top: 100px;
        margin-left: 0;
        text-align: center;
    }

    .footer-social input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .social-icons {
        margin-top: 15px;
        margin-right: 0;
        text-align: center;
    }

    .footer-bottom {
        color: #e7c061;
    }

    .footer-bottom p {
        margin-bottom: 10px;
    }

    .footer-bottom-links a {
        margin: 0 8px;
        color: #e7c061;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .footer-overlay {
        padding: 40px 25px;
    }

    .footer-top,
    .footer-middle {
        flex-direction: column;
        align-items: center;
        margin-bottom: 35px;
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-links a {
        margin: 20px 0;
        font-size: 1.5em;
    }

    .footer-info,
    .footer-social {
        max-width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    .footer-social p {
        margin-right: 0;
    }

    .footer-contact {
        margin-top: 120px;
        margin-left: 0;
        text-align: center;
    }

    .footer-social input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .social-icons {
        margin-top: 20px;
        margin-right: 0;
        text-align: center;
    }

    .footer-bottom p {
        margin-bottom: 10px;
    }

    .footer-bottom-links a {
        margin: 0 9px;
    }
}