/* General Reset to Avoid Horizontal Scrolling */
body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* Ensure the overall layout doesn't exceed the viewport width */
.reviews-top-section,
.reviews-image-container,
.reviews-main-section {
    max-width: 100vw;
    overflow-x: hidden;
}

/* Make sure that the image and heading inside the top section fit within the viewport */
.reviews-top-section {
    position: relative;
    overflow-x: hidden;
    /* Prevent horizontal scroll */
}

.reviews-image-container {
    width: 100%;
    height: 100vh;
    /* Set the height to 100% of the viewport height */
    overflow: hidden;
    position: relative;
}

.reviews-image {
    width: 100%;
    height: 100%;
    /* Ensure the image fills the container */
    object-fit: cover;
    /* Ensure the image covers the entire container without distorting */
}

.reviews-heading {
    position: absolute;
    bottom: 100px;
    /* Position the heading from the bottom of the container */
    left: 320px;
    /* Position the heading from the left of the container */
    margin: 0;
    padding: 10px 20px;
    color: white;
    font-size: 4.5em;
    /* Adjust font size as needed */
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;

    /* Ensure the heading stays within the viewport */
    max-width: calc(100vw - 20px);
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.reviews-main-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    max-width: 100vw;
    /* Prevent horizontal scrolling */
}

.reviews-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}

.reviews-form {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    /* Ensure the form doesn't exceed the container width */
}

.review-input,
.review-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-width: 100%;
    /* Ensure the inputs don't exceed the form width */
}

.review-textarea {
    height: 100px;
    resize: none;
}

.stars {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 100%;
}

.stars span {
    font-size: 24px;
    cursor: pointer;
    margin-right: 5px;
    transition: color 0.2s ease;
}

.filled-star {
    color: #FFD700;
    /* Gold color for filled stars */
}

.empty-star {
    color: #ddd;
    /* Grey color for empty stars */
}

.photo-upload {
    margin-bottom: 10px;
    max-width: 100%;
}

.photo-upload input {
    width: 100%;
    max-width: 100%;
}

.submit-review-button {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    align-self: flex-start;
}

.submit-review-button:hover {
    background-color: #555;
}

.image-gallery {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 100%;
}

.image-gallery img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.reviews-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;
}

.single-review {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    overflow-x: hidden;
}

.rating-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.review-images {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    max-width: 100%;
    overflow-x: auto;
    /* Allow horizontal scroll within the image section if necessary */
}

.review-images img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.review-author {
    text-align: right;
    font-style: italic;
    color: #555;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .reviews-heading {
        bottom: 40px;
        left: 20px;
        font-size: 2em;
        text-align: center;
    }

    .reviews-main-section {
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }

    .reviews-form-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .reviews-list-container {
        width: 100%;
    }

    .rating-date {
        flex-direction: column;
        align-items: flex-start;
    }

    .review-images {
        justify-content: flex-start;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .reviews-heading {
        bottom: 50px;
        left: 30px;
        font-size: 2.5em;
        text-align: center;
    }

    .reviews-main-section {
        flex-direction: column;
        padding: 15px;
        gap: 15px;
    }

    .reviews-form-container {
        width: 100%;
        margin-bottom: 25px;
    }

    .reviews-list-container {
        width: 100%;
    }

    .rating-date {
        flex-direction: column;
        align-items: flex-start;
    }

    .review-images {
        justify-content: flex-start;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .reviews-heading {
        bottom: 60px;
        left: 40px;
        font-size: 3em;
        text-align: center;
    }

    .reviews-main-section {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .reviews-form-container {
        width: 100%;
        margin-bottom: 30px;
    }

    .reviews-list-container {
        width: 100%;
    }

    .rating-date {
        flex-direction: column;
        align-items: flex-start;
    }

    .review-images {
        justify-content: flex-start;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .reviews-heading {
        bottom: 70px;
        left: 50px;
        font-size: 3.5em;
        text-align: center;
    }

    .reviews-main-section {
        flex-direction: column;
        padding: 25px;
        gap: 25px;
    }

    .reviews-form-container {
        width: 100%;
        margin-bottom: 35px;
    }

    .reviews-list-container {
        width: 100%;
    }

    .rating-date {
        flex-direction: column;
        align-items: flex-start;
    }

    .review-images {
        justify-content: flex-start;
    }
}