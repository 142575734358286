.terms-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.terms-content {
    flex: 1;
    max-width: 800px;
    background-color: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 200px;
    margin-bottom: 40px;
    margin-left: 300px;

}

.terms-heading {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.terms-text {
    font-size: 1.125rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.footer {
    margin-top: auto;
    /* Push the footer to the bottom of the container */
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .terms-content {
        max-width: 100%;
        padding: 20px;
        margin-top: 200px;
        margin-left: 0;
        margin-right: 0;
        box-shadow: none;
    }

    .terms-heading {
        font-size: 1.8rem;
        margin-bottom: 20px;
    }

    .terms-text {
        font-size: 1rem;
        line-height: 1.6;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .terms-content {
        max-width: 100%;
        padding: 20px;
        margin-top: 30px;
        margin-left: 0;
        margin-right: 0;
    }

    .terms-heading {
        font-size: 2rem;
        margin-bottom: 25px;
    }

    .terms-text {
        font-size: 1.05rem;
        line-height: 1.7;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .terms-content {
        max-width: 100%;
        padding: 30px;
        margin-top: 200px;
        margin-left: 0;
        margin-right: 0;
    }

    .terms-heading {
        font-size: 2.2rem;
        margin-bottom: 25px;
    }

    .terms-text {
        font-size: 1.1rem;
        line-height: 1.7;
        text-align: center;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .terms-content {
        max-width: 90%;
        padding: 40px;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
    }

    .terms-heading {
        font-size: 2.3rem;
        margin-bottom: 30px;
    }

    .terms-text {
        font-size: 1.125rem;
        line-height: 1.75;
    }
}