.ssbb-top-section {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #f4f4f4;
}

.ssbb-image-container {
    position: relative;
    width: 100%;
    height: 400px;
}

.ssbb-background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ssbb-text-container {
    position: absolute;
    top: 70%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    transform: translateY(-50%);
    color: white;
}

.ssbb-text-container h2 {
    font-size: 2.5em;
    font-weight: bold;
    margin-left: 20px;
    padding: 0;
    text-align: left;
    font-weight: bold;
}

.ssbb-text-container h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-right: 30px;
    padding: 0;
    text-align: right;
}

/* New Slider Section */
.ssbb-slider-container {
    width: 80%;
    margin: 80px auto;
    /* Center the slider container on the page */
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
}

.ssbb-large-image {
    width: 80%;
    height: auto;
    overflow: hidden;
    margin: 0 auto 20px auto;
    /* Center the image horizontally */
    display: block;
    /* Ensure image is treated as a block-level element */
}

.ssbb-slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    border-bottom: 2px solid #d4af37;
}

.ssbb-thumbnail-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}

.ssbb-thumbnail {
    width: 100px;
    height: 70px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease;
}

.ssbb-thumbnail.active {
    border: 2px solid #d4af37;
}

/* New Room Details Section */
.room-details {
    text-align: center;
    margin: 40px auto;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.details-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
}

.detail-item {
    width: 45%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 300px;
}

.detail-item img {
    width: 24px;
    height: 24px;
}

.detail-item p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

.room-description {
    font-size: 1rem;
    color: #666;
    text-align: center;
    margin-top: 20px;
}

/* New Room Services Section */
.room-services {
    text-align: center;
    margin: 40px auto;
    padding: 20px;
    width: 80%;
    max-width: 1200px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.room-services h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.service-item {
    width: 45%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 5px;
    text-align: left;
}

.service-item p {
    margin: 0;
    color: #666;
    font-size: 1rem;
}

.service-item svg {
    color: #666;
}

.other-rooms {
    margin: 40px auto;
    padding: 20px;
    width: 80%;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: left;
}

.other-rooms h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.other-rooms-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.room-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
}

.room-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.room-list-item a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.room-list-item a:hover {
    color: #d4af37;
    /* Gold color on hover */
}

.other-rooms-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    margin-right: 100px;
    margin-top: 30px;
}

.other-rooms-images img {
    width: 500px;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .ssbb-text-container {
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .ssbb-text-container h2 {
        font-size: 1.8em;
        margin-bottom: 5px;
    }

    .ssbb-text-container h3 {
        font-size: 1.4em;
        margin-top: 5px;
    }

    .ssbb-slider-container {
        width: 90%;
        padding: 10px;
        margin: 40px auto;
    }

    .ssbb-large-image {
        width: 100%;
        margin: 0 auto 15px auto;
    }

    .ssbb-thumbnail-container {
        flex-wrap: wrap;
        gap: 5px;
        justify-content: center;
    }

    .ssbb-thumbnail {
        width: calc(50% - 5px);
        margin-bottom: 10px;
    }



    .detail-item {
        margin-right: 40px;
    }

    .detail-item img {
        width: 24px;
        height: 24px;
    }

    .room-description {
        font-size: 1rem;
        text-align: center;
        margin-top: 20px;
    }

    .other-rooms {
        width: 90%;
        padding: 10px;
    }

    .other-rooms-content {
        flex-direction: column;
    }

    .room-list,
    .other-rooms-images {
        margin: 0;
        width: 100%;
    }

    .room-list-item {
        margin-bottom: 15px;
    }

    .other-rooms-images {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .other-rooms-images img {
        width: 80%;
        height: auto;
        max-width: 300px;
        /* Set a max width to limit the size */
    }


}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .ssbb-text-container {
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .ssbb-text-container h2 {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .ssbb-text-container h3 {
        font-size: 1.6em;
        margin-top: 10px;
    }

    .ssbb-slider-container {
        width: 85%;
        padding: 15px;
        margin: 60px auto;
    }

    .ssbb-large-image {
        width: 100%;
        margin: 0 auto 20px auto;
    }

    .ssbb-thumbnail-container {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .ssbb-thumbnail {
        width: calc(50% - 10px);
        margin-bottom: 10px;
    }

    .room-details {
        width: 90%;
        padding: 20px;
        margin: 30px auto;
    }

    .details-list {
        flex-direction: column;
        align-items: center;
    }

    .detail-item {
        width: 100%;
        margin-left: 0;
        justify-content: center;
    }

    .detail-item img {
        width: 22px;
        height: 22px;
    }

    .detail-item p {
        font-size: 0.95rem;
    }

    .room-description {
        font-size: 0.95rem;
    }

    .room-details {
        width: 85%;
        padding: 20px;
        margin: 30px auto;
    }

    .details-list {
        flex-direction: column;
        align-items: center;
    }

    .detail-item {
        width: 100%;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 15px;
    }

    .detail-item img {
        width: 24px;
        height: 24px;
    }

    .detail-item p {
        font-size: 1rem;
    }

    .room-description {
        font-size: 1rem;
        margin-top: 20px;
    }

    .other-rooms {
        width: 90%;
        padding: 15px;
    }

    .other-rooms-content {
        flex-direction: column;
    }

    .room-list,
    .other-rooms-images {
        width: 100%;
    }

    .room-list-item {
        margin-bottom: 15px;
    }

    .other-rooms-images {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .other-rooms-images img {
        width: 80%;
        height: auto;
        max-width: 350px;
        /* Set a max width to limit the size */
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .ssbb-text-container {
        margin-top: 100px;
        transform: translateY(-50%);
        margin-right: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .ssbb-text-container h2 {
        font-size: 2.2em;
        margin-bottom: 10px;
        margin-right: 20px;
    }

    .ssbb-text-container h3 {
        font-size: 1.6em;
        margin-top: 10px;
    }

    .ssbb-slider-container {
        width: 85%;
        padding: 20px;
        margin: 70px auto;
    }

    .ssbb-large-image {
        width: 90%;
        margin: 0 auto 20px auto;
    }

    .ssbb-thumbnail-container {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .ssbb-thumbnail {
        width: calc(50% - 10px);
        margin-bottom: 15px;
    }





    .detail-item {
        margin-left: 0%;
    }

    .detail-item img {
        width: 28px;
        height: 28px;
    }

    .detail-item p {
        font-size: 1.1rem;
    }

    .room-description {
        font-size: 1.1rem;
        margin-top: 25px;
    }

    .other-rooms {
        width: 85%;
        padding: 15px;
    }

    .other-rooms-content {
        flex-direction: column;
    }

    .room-list,
    .other-rooms-images {
        width: 100%;
    }

    .room-list-item {
        margin-bottom: 15px;
    }

    .other-rooms-images {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
    }

    .other-rooms-images img {
        width: 80%;
        height: 200px;
        margin-left: 40px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .ssbb-text-container {
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .ssbb-text-container h2 {
        font-size: 2.4em;
        margin-bottom: 15px;
    }

    .ssbb-text-container h3 {
        font-size: 1.7em;
        margin-top: 15px;
    }

    .ssbb-slider-container {
        width: 80%;
        padding: 20px;
        margin: 80px auto;
    }

    .ssbb-large-image {
        width: 85%;
        margin: 0 auto 20px auto;
    }

    .ssbb-thumbnail-container {
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
    }

    .ssbb-thumbnail {
        width: calc(50% - 15px);
        margin-bottom: 15px;
    }

    .room-details {
        width: 80%;
        padding: 20px;
        margin: 50px auto;
    }

    .details-list {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .detail-item {
        width: 45%;
        margin-left: 0;
        justify-content: center;
    }

    .detail-item img {
        width: 24px;
        height: 24px;
    }

    .detail-item p {
        font-size: 1rem;
    }

    .room-description {
        font-size: 1rem;
    }

    .other-rooms {
        width: 90%;
        padding: 20px;
    }

    .other-rooms-content {
        flex-direction: column;
    }

    .room-list,
    .other-rooms-images {
        width: 100%;
    }

    .room-list-item {
        margin-bottom: 15px;
    }

    .other-rooms-images {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
    }

    .other-rooms-images img {
        width: 100%;
        height: auto;
    }
}