.gallery-top-section {
    position: relative;
}

.gallery-image-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-heading {
    position: absolute;
    bottom: 100px;
    left: 400px;
    margin: 0;
    padding: 10px 20px;
    color: white;
    font-size: 4.5em;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

.gallery-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0;
}

.gallery-button {
    background-color: #d4af37;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    transition: background-color 0.3s ease;
}

.gallery-button.active {
    background-color: #b58523;
}

.gallery-button:hover {
    background-color: #b58523;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    padding: 20px;
}

.gallery-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover {
    transform: scale(1.05);
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .gallery-image-container {
        height: 50vh;
    }

    .gallery-heading {
        font-size: 2.5em;
        bottom: 50px;
        left: 20px;
    }



    .gallery-grid {
        grid-template-columns: 1fr;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .gallery-image-container {
        height: 60vh;
    }

    .gallery-heading {
        font-size: 3em;
        bottom: 40px;
        left: 20px;
    }

    .gallery-buttons {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .gallery-button {
        width: auto;
        padding: 10px 20px;
    }

    .gallery-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .gallery-image-container {
        height: 60vh;
    }

    .gallery-heading {
        font-size: 3.5em;
        bottom: 60px;
        left: 40px;
    }



    .gallery-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .gallery-image-container {
        height: 70vh;
    }

    .gallery-heading {
        font-size: 4em;
        bottom: 80px;
        left: 60px;
    }

    .gallery-buttons {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .gallery-button {
        width: auto;
        padding: 10px 20px;
    }

    .gallery-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}