/* General container styling */
.sitemap-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f4f4;
    /* Light background color */
}

/* Content area styling */
.sitemap-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* Center align the text */
    padding: 40px 20px;
    box-sizing: border-box;
    margin-top: 200px;
}

/* Styling for the h1 heading */
.sitemap-content h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
    font-family: 'Georgia', serif;
    /* Stylish font for heading */
}

/* Styling for the sitemap list */
.sitemap {
    font-size: 1.2rem;
    color: #333;
    max-width: 600px;
    /* Limit the width of the list */
    margin: 0 auto;
    /* Center the list */
}

/* Top-level list styling */
.sitemap ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* List items styling */
.sitemap ul>li {
    margin-bottom: 20px;
    /* Increased space between items */
    font-weight: bold;
}

/* Links styling */
.sitemap a {
    color: #e7c061;
    /* Dark blue color for links */
    text-decoration: none;
    font-family: 'Arial', sans-serif;
    /* Clean font for links */
}

/* Hover effect for links */
.sitemap a:hover {
    text-decoration: underline;
    color: #002f6c;
    /* Darker blue on hover */
}

/* Nested list styling */
.sitemap ul ul {
    margin-left: 20px;
    margin-top: 10px;
    list-style-type: disc;
    text-align: left;
    /* Align nested lists to the left */
    font-size: 1rem;
}

/* Footer styling */
.footer {
    margin-top: auto;
    /* Pushes footer to the bottom */
}

/* Responsive Design: Adjusts content for smaller screens */
@media (max-width: 768px) {
    .sitemap-content {
        padding: 20px;
    }

    .sitemap-content h1 {
        font-size: 2rem;
    }

    .sitemap {
        font-size: 1rem;
        max-width: 100%;
    }
}