.card {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    /* Adjust to match the width of the card in the image */
    height: 256px;
    /* Adjust to match the height of the card in the image */
    margin: 40px 0;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.card-image {
    width: 40%;
    height: 100%;
    object-fit: cover;
    margin-right: 20px;
}

.card-content {
    width: 60%;
    padding: 10px;
}

.card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.card-subtitle {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 15px;
}

.card-price {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
}

.card-icons {
    display: flex;
    gap: 10px;
    /* Adjust spacing between icons */
    margin-bottom: 15px;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    /* Adjust to fit your design */
    height: 40px;
    /* Adjust to fit your design */
    border: 1px dashed #ccc;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.icon-container:hover {
    background-color: #f9f9f9;
}

.icon-container::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
}

.icon-container:hover::after {
    opacity: 1;
}

.icon-container svg {
    width: 20px;
    /* Adjust the size of the icons */
    height: 20px;
    color: #666;
    /* Adjust the icon color */
}

.card-link {
    display: inline-block;
    margin-top: 10px;
    color: #d4a056;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.card-link:hover {
    color: #b38d44;
}

.rooms-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.pagination button.active {
    background-color: #d4af37;
    color: white;
}

.pagination button:disabled {
    background-color: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) {
    .card {
        flex-direction: column;
        width: 70%;
        /* Reduced width */
        max-width: 300px;
        /* Reduced max-width */
        height: auto;
        margin: 20px 10px 20px 10px;
        /* Right margin added */
        padding: 15px;
    }

    .card-image {
        width: 100%;
        height: 180px;
        /* Adjusted height */
        margin-bottom: 10px;
    }

    .card-content {
        width: 100%;
        padding: 5px;
    }

    .card-title {
        font-size: 1.1rem;
        /* Adjusted font size */
    }

    .card-subtitle {
        font-size: 0.9rem;
    }

    .card-price {
        font-size: 0.9rem;
        /* Adjusted font size */
    }

    .card-icons {
        justify-content: center;
    }

    .icon-container {
        width: 25px;
        /* Adjusted size */
        height: 25px;
    }

    .icon-container svg {
        width: 12px;
        /* Adjusted icon size */
        height: 12px;
    }

    .rooms-list {
        width: 100%;
        padding-left: 0%;
    }

    .pagination {
        margin-top: 15px;
    }

    .pagination {
        margin-right: 50px;
    }

    .pagination button {
        padding: 4px;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .card {
        flex-direction: column;
        width: 85%;
        /* Reduced width */
        max-width: 350px;
        /* Reduced max-width */
        height: auto;
        margin: 25px 15px 25px 0;
        /* Right margin added */
        padding: 15px;
    }

    .card-image {
        width: 100%;
        height: 180px;
        margin-bottom: 15px;
    }

    .card-content {
        width: 100%;
        padding: 10px;
    }

    .card-title {
        font-size: 1.2rem;
        /* Adjusted font size */
    }

    .card-subtitle {
        font-size: 0.95rem;
    }

    .card-price {
        font-size: 1rem;
        /* Adjusted font size */
    }

    .icon-container {
        width: 30px;
        /* Adjusted size */
        height: 30px;
    }

    .icon-container svg {
        width: 15px;
        /* Adjusted icon size */
        height: 15px;
    }

    .rooms-list {
        width: 100%;
        padding: 0 15px;
    }

    .pagination button {
        padding: 5px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) {
    .card {
        flex-direction: column;
        width: 70%;
        /* Reduced width */
        max-width: 400px;
        /* Reduced max-width */
        height: auto;
        margin: 30px 20px 30px 20px;
        /* Right margin added */
        padding: 18px;
    }

    .card-image {
        width: 100%;
        height: 200px;
        /* Adjusted height */
        margin-bottom: 20px;
    }

    .card-content {
        width: 100%;
        padding: 15px;
    }

    .card-title {
        font-size: 1.3rem;
        /* Adjusted font size */
    }

    .card-subtitle {
        font-size: 1rem;
    }

    .card-price {
        font-size: 1.1rem;
    }

    .icon-container {
        width: 35px;
        height: 35px;
    }

    .icon-container svg {
        width: 18px;
        height: 18px;
    }

    .rooms-list {
        width: 100%;
        padding: 0 20px;
    }

    .pagination {
        margin-right: 50px;
    }

    .pagination button {
        padding: 7px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .card {
        flex-direction: column;
        width: 75%;
        /* Reduced width */
        max-width: 450px;
        /* Reduced max-width */
        height: auto;
        margin: 35px 25px 35px 0;
        /* Right margin added */
        padding: 20px;
    }

    .card-image {
        width: 100%;
        height: 220px;
        /* Adjusted height */
        margin-bottom: 20px;
    }

    .card-content {
        width: 100%;
        padding: 15px;
    }

    .card-title {
        font-size: 1.4rem;
        /* Adjusted font size */
    }

    .card-subtitle {
        font-size: 1.1rem;
    }

    .card-price {
        font-size: 1.2rem;
    }

    .icon-container {
        width: 40px;
        height: 40px;
    }

    .icon-container svg {
        width: 20px;
        height: 20px;
    }

    .rooms-list {
        width: 100%;
        padding: 0 25px;
    }

    .pagination button {
        padding: 8px;
    }
}