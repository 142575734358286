.admin-register-container {
    display: flex;
    height: 100vh;
    font-family: 'Arial', sans-serif;
}

.admin-register-left {
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 50%;
    /* Adjust according to your design */
}

.admin-register-left img {
    max-width: 150px;
    margin-bottom: 20px;
}

.admin-register-left h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.admin-register-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* Adjust according to your design */
    background-color: #f8f8f8;
}

.admin-register-box {
    width: 350px;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.admin-register-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.admin-register-form {
    display: flex;
    flex-direction: column;
}

.admin-register-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.admin-register-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.admin-register-button:hover {
    background-color: #0056b3;
}

.admin-register-link {
    margin-top: 15px;
    text-align: center;
}

.admin-register-link a {
    color: #28a745;
    text-decoration: none;
}

.admin-register-link a:hover {
    text-decoration: underline;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .admin-register-container {
        flex-direction: column;
        height: auto;
    }

    .admin-register-left,
    .admin-register-right {
        width: 100%;
    }

    .admin-register-left {
        justify-content: center;
        align-items: center;
    }

    .admin-register-left img {
        max-width: 100px;
    }

    .admin-register-left h2 {
        font-size: 20px;
    }

    .admin-register-box {
        width: 100%;
        padding: 20px;
        box-shadow: none;
        margin-top: 50px;
    }

    .admin-register-title {
        font-size: 20px;
    }

    .admin-register-input {
        font-size: 14px;
        padding: 8px;
    }

    .admin-register-button {
        font-size: 14px;
        padding: 8px;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .admin-register-container {
        flex-direction: column;
        height: auto;
    }

    .admin-register-left,
    .admin-register-right {
        width: 100%;
    }

    .admin-register-left img {
        max-width: 120px;
    }

    .admin-register-left h2 {
        font-size: 22px;
    }

    .admin-register-box {
        width: 100%;
        padding: 30px;
    }

    .admin-register-title {
        font-size: 22px;
    }

    .admin-register-input {
        font-size: 15px;
        padding: 10px;
    }

    .admin-register-button {
        font-size: 15px;
        padding: 10px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .admin-register-container {
        flex-direction: column;
        height: auto;
    }

    .admin-register-left,
    .admin-register-right {
        width: 100%;
    }

    .admin-register-left img {
        max-width: 130px;
    }

    .admin-register-left h2 {
        font-size: 24px;
    }

    .admin-register-box {
        width: 100%;
        padding: 35px;
        margin-top: 50px;
    }

    .admin-register-title {
        font-size: 24px;
    }

    .admin-register-input {
        font-size: 16px;
        padding: 10px;
    }

    .admin-register-button {
        font-size: 16px;
        padding: 12px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .admin-register-container {
        flex-direction: row;
        height: 100vh;
    }

    .admin-register-left,
    .admin-register-right {
        width: 50%;
    }

    .admin-register-left img {
        max-width: 140px;
    }

    .admin-register-left h2 {
        font-size: 24px;
    }

    .admin-register-box {
        width: 350px;
        padding: 40px;
    }

    .admin-register-title {
        font-size: 24px;
    }

    .admin-register-input {
        font-size: 16px;
    }

    .admin-register-button {
        font-size: 16px;
    }
}