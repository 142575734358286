/* AboutUs.css */

.about-top-section {
    position: relative;
}

.about-image-container {
    width: 100%;
    height: 100vh;
    /* Set the height to 100% of the viewport height */
    overflow: hidden;
    position: relative;
}

.about-image {
    width: 100%;
    height: 100%;
    /* Ensure the image fills the container */
    object-fit: cover;
    /* Ensure the image covers the entire container without distorting */
}

.about-heading {
    position: absolute;
    bottom: 110px;
    /* Position the heading 20px from the bottom of the container */
    left: 350px;
    /* Position the heading 20px from the left of the container */
    margin: 0;
    padding: 10px 20px;
    color: white;
    font-size: 4.5em;
    /* Adjust font size as needed */
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;

}

.about-content-section {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    align-items: center;
}

.about-left-column {
    flex: 1;
    padding-right: 20px;
}

.about-left-column h2 {
    font-size: 4em;
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #BDB76B;
}

.about-left-column h3 {
    font-size: 2em;
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #BDB76B;
}

.about-left-column p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #A9A9A9;
}

.about-right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.right-image {
    width: 80%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: 80px;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .about-image-container {
        height: 70vh;
        /* Set the height to 70% of the viewport height */
    }

    .about-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-heading {
        font-size: 2em;
        bottom: 20px;
        left: 10px;
    }

    .about-content-section {
        flex-direction: column;
        padding: 20px;
        text-align: center;
        align-items: center;
    }

    .about-left-column,
    .about-right-column {
        width: 100%;
        padding: 10px;
    }

    .about-left-column h2 {
        font-size: 2.5em;
    }

    .about-left-column h3 {
        font-size: 1.5em;
    }

    .about-left-column p {
        font-size: 1rem;
    }

    .right-image {
        width: 100%;
        margin-left: 0;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .about-image-container {
        height: 70vh;
    }

    .about-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-heading {
        font-size: 2.5em;
        bottom: 20px;
        left: 10px;
    }

    .about-content-section {
        flex-direction: column;
        padding: 20px;
        text-align: center;
        align-items: center;
    }

    .about-left-column,
    .about-right-column {
        width: 100%;
        padding: 10px;
    }

    .about-left-column h2 {
        font-size: 3em;
    }

    .about-left-column h3 {
        font-size: 1.8em;
    }

    .about-left-column p {
        font-size: 1rem;
    }

    .right-image {
        width: 100%;
        margin-left: 0;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .about-image-container {
        height: 70vh;
    }

    .about-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-heading {
        font-size: 3em;
        bottom: 30px;
        left: 20px;
    }

    .about-content-section {
        flex-direction: column;
        padding: 30px;
        text-align: center;
        align-items: center;
    }

    .about-left-column,
    .about-right-column {
        width: 100%;
        padding: 15px;
    }

    .about-left-column h2 {
        font-size: 3.5em;
    }

    .about-left-column h3 {
        font-size: 2em;
    }

    .about-left-column p {
        font-size: 1.1rem;
    }

    .right-image {
        width: 90%;
        margin-left: 20px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .about-image-container {
        height: 70vh;
    }

    .about-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-heading {
        font-size: 4em;
        bottom: 40px;
        left: 30px;
    }

    .about-content-section {
        flex-direction: column;
        padding: 40px;
        text-align: center;
        align-items: center;
    }

    .about-left-column,
    .about-right-column {
        width: 100%;
        padding: 20px;
    }

    .about-left-column h2 {
        font-size: 4em;
    }

    .about-left-column h3 {
        font-size: 2.2em;
    }

    .about-left-column p {
        font-size: 1.2rem;
    }

    .right-image {
        width: 90%;
        margin-left: 0;
    }
}

/* Desktop (larger than 1024px) */
@media only screen and (min-width: 1025px) {
    .about-image-container {
        height: 70vh;
    }

    .about-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .about-heading {
        font-size: 4.5em;
        bottom: 110px;
        left: 350px;
    }

    .about-content-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 50px;
        align-items: center;
    }

    .about-left-column {
        flex: 1;
        padding-right: 20px;
    }

    .about-left-column h2 {
        font-size: 4em;
    }

    .about-left-column h3 {
        font-size: 2em;
    }

    .about-left-column p {
        font-size: 1.2rem;
    }

    .about-right-column {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .right-image {
        width: 80%;
        margin-left: 80px;
    }
}