/* Container for the entire privacy policy page */
.privacy-container {
    background-color: #f8f8f8;

    box-sizing: border-box;
}

/* Content area styling */
.privacy-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 200px;
    margin-bottom: 40px;
}

/* Heading styling */
.privacy-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

/* Date styling */
.privacy-date {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
    text-align: center;
}

/* Subheadings styling */
.privacy-subheading {
    font-size: 1.5rem;
    margin-top: 30px;
    color: #444;
}

/* Paragraph text styling */
.privacy-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
}

/* List styling */
.privacy-list {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 20px;
}

/* Footer specific styling for proper alignment */
.footer {
    margin-top: 40px;
}