.search-page-container {
    width: 100%;
    text-align: center;
    font-family: Arial, sans-serif;
}

.top-banner-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.booking-heading {
    font-size: 2.5rem;
    margin-top: 20px;
    color: #333;
}

.booking-container {
    display: flex;
    margin-top: 40px;
    padding: 20px;
    justify-content: space-between;
}

.filter-container {
    width: 25%;
    padding-right: 20px;
}

.filter-item {
    margin-bottom: 20px;
}

/* Date Input Field Styling */
.filter-item input[type="text"],
.filter-item input[type="number"],
.filter-item input[type="range"] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    color: #333;
    background-color: #fff;
    cursor: pointer;
}

.filter-item input[type="text"]:focus,
.filter-item input[type="number"]:focus,
.filter-item input[type="range"]:focus {
    outline: none;
    border-color: #D4A017;
    box-shadow: 0 0 5px rgba(212, 160, 23, 0.5);
}

.filter-item input[type="text"]::placeholder,
.filter-item input[type="number"]::placeholder {
    color: #aaa;
}

/* Price Range Display Styling */
.price-range-display {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-weight: bold;
}

/* Calendar Popup Styling */
.react-date-range {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.react-date-range .rdrCalendarWrapper {
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* Styling for the date range label */
.filter-item label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

/* Additional Styling for Calendar Elements */
.react-date-range .rdrDayNumber span {
    color: #333;
}

.react-date-range .rdrDayStartPreview,
.react-date-range .rdrDayInPreview,
.react-date-range .rdrDayEndPreview {
    background: rgba(212, 160, 23, 0.5);
    border: 1px solid #D4A017;
}

.react-date-range .rdrSelected {
    background-color: #D4A017;
    color: #fff;
}

/* Room List and Cards Styling */
.room-list-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sort-container {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 810px;
}

.room-card-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    padding: 15px;
    background-color: #fff;
    align-items: flex-start;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.room-card-container:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.room-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 60px;
    margin-top: 40px;
}

.room-details {
    flex-grow: 1;
    text-align: left;
}

.room-name {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

.room-price {
    margin: 10px 0;
    color: #666;
    font-weight: bold;
    font-size: 1.2rem;
}

.room-total-price {
    font-size: 16px;
    color: #D4A017;
    font-weight: bold;
}

.room-icons-container {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.icon-with-tooltip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.icon-with-tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.icon-with-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.room-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #D4A017;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-weight: bold;
}

.room-button:hover {
    background-color: #E1B546;
}

.room-details-window {
    width: 100%;
    margin-top: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.room-details-window table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.room-details-window th,
.room-details-window td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.room-details-window .available {
    background-color: #add8e6;
}

.room-details-window .not-available {
    background-color: #ffcccb;
}

.legend {
    display: flex;
    justify-content: space-around;
}

.legend span {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
}

/* Pagination Styling */
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-container button {
    padding: 10px 15px;
    margin: 0 5px;
    border: none;
    background-color: #E1B546;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination-container button.active-page {
    background-color: #D4A017;
}

.pagination-container button:hover {
    background-color: #0056b3;
}

.pagination-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Book Now Button Styling */
.book-now-button {
    width: 100%;
    padding: 10px;
    background-color: #D4A017;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.book-now-button:hover {
    background-color: #E1B546;
}

/* Add this to your Search.css file */
.search-button {
    width: 100%;
    padding: 10px;
    background-color: #D4A017;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #E1B546;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {


    .top-banner-image {
        height: 200px;
    }

    .booking-heading {
        font-size: 1.8rem;
        margin-top: 10px;
    }

    .booking-container {
        flex-direction: column;
        padding: 10px;
        margin-top: 20px;
    }

    .filter-container {
        width: 100%;
        padding-right: 0;
    }

    .room-list-container {
        width: 100%;
    }

    .room-card-container {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: center;
    }

    .room-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-top: 10px;
    }

    .sort-container {
        margin-left: 0;
        text-align: center;
        margin-bottom: 10px;
    }

    .pagination-container {
        display: flex;
        gap: 10px;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {


    .top-banner-image {
        height: 250px;
    }

    .booking-heading {
        font-size: 2rem;
    }

    .booking-container {
        flex-direction: column;
        padding: 15px;
        margin-top: 20px;
    }

    .filter-container {
        width: 100%;
        padding-right: 0;
    }

    .room-list-container {
        width: 100%;
    }

    .room-card-container {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: center;
    }

    .room-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-top: 10px;
    }

    .sort-container {
        margin-left: 0;
        text-align: center;
        margin-bottom: 10px;
    }

    .pagination-container {
        display: flex;
        gap: 10px;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {


    .top-banner-image {
        height: 300px;
    }

    .booking-heading {
        font-size: 2.2rem;
    }

    .booking-container {
        flex-direction: column;
        padding: 20px;
        margin-top: 30px;
    }

    .filter-container {
        width: 100%;
        padding-right: 0;
    }

    .room-list-container {
        width: 100%;
    }

    .room-card-container {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: center;
    }

    .room-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-top: 10px;
    }

    .sort-container {
        margin-left: 0;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .pagination-container {
        display: flex;
        gap: 10px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {


    .top-banner-image {
        height: 350px;
    }

    .booking-heading {
        font-size: 2.4rem;
    }

    .booking-container {
        flex-direction: column;
        padding: 30px;
        margin-top: 30px;
    }

    .filter-container {
        width: 100%;
        padding-right: 0;
    }

    .room-list-container {
        width: 100%;
    }

    .room-card-container {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: center;
    }

    .room-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-top: 10px;
    }

    .sort-container {
        margin-left: 0;
        text-align: center;
        margin-bottom: 10px;
    }

    .pagination-container {
        display: flex;
        gap: 10px;
    }
}