.gallery-update {
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.image-upload-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.image-upload-section input,
.image-upload-section select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 250px;
}

.image-upload-section button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.image-upload-section button:hover {
    background-color: #0056b3;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.gallery-item {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.gallery-item:hover {
    transform: translateY(-5px);
}

.gallery-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.gallery-category {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 0.9rem;
}

.gallery-item button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.gallery-item button:hover {
    background-color: rgba(255, 0, 0, 1);
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    h1 {
        font-size: 1.5rem;
    }

    .image-upload-section {
        flex-direction: column;
        gap: 10px;
    }

    .image-upload-section input,
    .image-upload-section select {
        width: 100%;
    }

    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
    }

    .gallery-item img {
        height: 120px;
    }

    .gallery-category {
        font-size: 0.8rem;
    }

    .gallery-item button {
        margin-top: 30px;
        font-size: 0.9rem;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    h1 {
        font-size: 1.75rem;
    }

    .image-upload-section {
        flex-direction: column;
        gap: 10px;
    }

    .image-upload-section input,
    .image-upload-section select {
        width: 100%;
    }

    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        gap: 20px;
    }

    .gallery-item img {
        height: 130px;
    }

    .gallery-category {
        font-size: 0.85rem;
    }

    .gallery-item button {
        font-size: 0.95rem;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    h1 {
        font-size: 1.8rem;
    }

    .image-upload-section {
        flex-direction: column;
        gap: 10px;
    }

    .image-upload-section input,
    .image-upload-section select {
        width: 100%;
    }

    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 20px;
    }

    .gallery-item img {
        height: 140px;
    }

    .gallery-category {
        font-size: 0.85rem;
    }

    .gallery-item button {
        font-size: 0.95rem;
        margin-top: 40px;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    h1 {
        font-size: 2rem;
    }

    .image-upload-section {
        flex-direction: row;
        gap: 15px;
    }

    .image-upload-section input,
    .image-upload-section select {
        width: 250px;
    }

    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
    }

    .gallery-item img {
        height: 150px;
    }

    .gallery-category {
        font-size: 0.9rem;
    }

    .gallery-item button {
        font-size: 1rem;
    }
}