/* General styles for all screen sizes */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    color: #fff;
    max-height: 150px;
    font-family: 'Times New Roman', Times, serif;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.navbar-logo img {
    max-height: 160px;
    width: auto;
}

.navbar-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
}

.navbar-links li {
    margin-right: 20px;
    position: relative;
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.8em;
}

.navbar-links a:hover {
    text-decoration: underline;
}

.book-online a {
    background-color: #FFC107;
    padding: 10px 20px;
    border-radius: 5px;
    color: #000;
    margin-right: 10px;
    font-size: 1.5em;
}

.book-online a:hover {
    background-color: #E0A800;
}

/* Dropdown Menu */
.dropdown {
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    color: #000;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
}

.dropdown-content a {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 1.2em;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Mobile Menu */
.menu-icon {
    display: none;
    cursor: pointer;
    font-size: 2em;
    align-self: center;
    margin-right: 30px;
}

.close-icon {
    display: none;
    cursor: pointer;
    font-size: 2em;
    align-self: flex-end;
    padding: 10px;
    color: white;
    margin-right: 40px;
}


@media screen and (max-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px 20px;
        color: #fff;
        max-height: 150px;
        font-family: 'Times New Roman', Times, serif;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1000;
    }

    .navbar-logo img {
        max-height: 150px;
        width: auto;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 0;
        background-color: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .navbar-links li {
        margin: 20px 0;
    }

    .navbar-links.active {
        display: flex;
    }

    .menu-icon {
        display: block;
    }

    .close-icon {
        display: block;
    }


}