.home-top-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.navbar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
}

.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
}

.slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation: fadeAnimation 25s infinite;
}

.slide:nth-child(1) {
    animation-delay: 0s;
}

.slide:nth-child(2) {
    animation-delay: 5s;
}

.slide:nth-child(3) {
    animation-delay: 10s;
}

.slide:nth-child(4) {
    animation-delay: 15s;
}

.slide:nth-child(5) {
    animation-delay: 20s;
}

@keyframes fadeAnimation {
    0% {
        opacity: 0;
    }

    4% {
        opacity: 1;
    }

    22% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.slide-indicators {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 20;
}

.indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.indicator.active {
    background-color: black;
    opacity: 1;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slide-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0;
    z-index: 15;
    margin-bottom: 120px;
    text-align: center;
    margin-left: 80px;
    font-size: 4em;
    font-family: 'Times New Roman', Times, serif;

    /* Add animation */
    animation: fadeInUp 1s ease-out forwards;
    animation-delay: 0.5s;
    /* Delay to start the animation */
}

.cards-container {
    position: absolute;
    bottom: 50px;
    left: 20px;
    display: flex;
    gap: 20px;
    z-index: 20;
    margin-left: 200px;

    /* Add animation */
    animation: fadeInLeft 1s ease-out forwards;
    animation-delay: 1.5s;
    /* Delay to start the animation after title */
}

.cards {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-left: 60px;

    /* Optional: You can add individual animations to each card if needed */
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 1s ease-out forwards;
    animation-delay: 2s;
    /* Staggered delay for cards */
}

.cards-icon {
    margin-right: 10px;
    color: #d4af37;
    /* Gold color similar to the original image */
}

.cards-text h2 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

.cards-text p {
    margin: 0;
    font-size: 0.8rem;
    color: #555;
}

.slide-indicators {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 20;
}

/* Phone Portrait (max-width: 480px) */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .home-top-section {
        height: 100vh;
    }

    .slideshow {
        height: 100vh;
    }

    .slide {
        height: 100vh;
    }

    .slide-title {
        font-size: 1.5rem;
        margin-bottom: 80px;
        /* Increased margin-bottom */
        padding-bottom: 60px;
        /* Added padding-bottom */
        margin-left: 10px;
        text-align: center;
    }

    .cards-container {
        display: flex;
        /* Display cards */
        flex-direction: column;
        /* Stack cards vertically on small screens */
        margin-top: 10px;
        margin-left: 10px;
    }

    .cards {
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }

    .slide-indicators {
        bottom: 10px;
        top: auto;
        right: 10px;
        flex-direction: row;
        justify-content: center;
    }
}

/* Phone Landscape (max-width: 812px) */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .home-top-section {
        height: 100vh;
    }

    .slideshow {
        height: 100vh;
    }

    .slide {
        height: 100vh;
    }

    .slide-title {
        font-size: 1.5rem;
        margin-top: 20px;
        margin-bottom: 90px;
        /* Increased margin-bottom */
        padding-bottom: 50px;
        /* Added padding-bottom */
        text-align: center;
        position: relative;
        z-index: 15;
    }

    .cards-container {
        display: flex;
        /* Display cards */
        flex-direction: column;
        /* Stack cards vertically */
        margin-top: 20px;
        margin-left: 20px;
    }

    .cards {
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }

    .slide-indicators {
        bottom: 20px;
        top: auto;
        right: 20px;
        flex-direction: row;
        justify-content: center;
    }
}

/* Tablet Portrait (max-width: 768px) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
    .home-top-section {
        height: 100vh;
    }

    .slideshow {
        height: 100vh;
    }

    .slide {
        height: 100vh;
    }

    .slide-title {
        font-size: 2.3em;
        margin-bottom: 200px;
        /* Increased margin-bottom */
        padding-bottom: 80px;
        /* Added padding-bottom */
        margin-right: 60px;
        text-align: center;
    }

    .cards-container {
        display: flex;
        /* Display cards */
        flex-direction: column;
        /* Stack cards vertically */
        margin-top: 30px;
        margin-left: 60px;
        margin-bottom: 20px;
    }

    .cards {
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }

    .slide-indicators {
        bottom: 30px;
        top: auto;
        right: 30px;
        flex-direction: row;
        justify-content: center;
    }
}

/* Tablet Landscape (max-width: 1024px) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .home-top-section {
        height: 100vh;
    }

    .slideshow {
        height: 100vh;
    }

    .slide {
        height: 100vh;
    }

    .slide-title {
        font-size: 3rem;
        margin-bottom: 120px;
        /* Increased margin-bottom */
        padding-bottom: 60px;
        /* Added padding-bottom */
        margin-left: 40px;
        text-align: center;
    }

    .cards-container {
        display: flex;
        /* Display cards */
        flex-direction: column;
        /* Stack cards vertically */
        margin-top: 40px;
        margin-left: 40px;
    }

    .cards {
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }

    .slide-indicators {
        bottom: 40px;
        top: auto;
        right: 40px;
        flex-direction: row;
        justify-content: center;
    }
}